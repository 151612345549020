import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = (n: any) => (_pushScopeId("data-v-e2a03b78"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "content" }
const _hoisted_2 = { class: "title" }
const _hoisted_3 = { class: "info" }
const _hoisted_4 = {
  key: 0,
  class: "info-source"
}
const _hoisted_5 = { class: "detail" }
const _hoisted_6 = ["innerHTML"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, _toDisplayString(_ctx.info.name), 1),
    _createElementVNode("div", _hoisted_3, [
      (_ctx.info.source)
        ? (_openBlock(), _createElementBlock("span", _hoisted_4, "来源：" + _toDisplayString(_ctx.info.source), 1))
        : _createCommentVNode("", true),
      _createElementVNode("span", null, "发布时间：" + _toDisplayString(_ctx.info.createTime), 1)
    ]),
    _createElementVNode("div", _hoisted_5, [
      _createElementVNode("div", {
        innerHTML: _ctx.info.body?_ctx.info.body:_ctx.info.content
      }, null, 8, _hoisted_6)
    ])
  ]))
}