
import {defineComponent} from 'vue'
import {useRoute} from "vue-router";
export default defineComponent({
  name: 'Detail',
  props: {
    info: {
      type: Object,
      default: function () {
        return {}
      }
    }
  },
})
